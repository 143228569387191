import React, { useContext } from 'react'

import AuthContext from "../context/AuthContext"

import Layout from '../components/customlayout'
import Guilds from "../components/guild"

const GuildsPage = () => {
    const { isLogged, me } = useContext(AuthContext);
    return (
        <Layout>
            <main className='container'>
                {/* <button className='btn btn-info' onClick={refreshMutual}
                style={{display:'flex'}}>REFRESH</button> */}
                {isLogged ?
                    <Guilds guilds={me.guilds} />
                    :
                    'login required'}
            </main>
        </Layout>
    )
}

export default GuildsPage