import React from 'react'
import { Link } from 'gatsby';

export function GuildPreview(props) {
    const { guild } = props;

    const img_url = () => {
        if (guild.icon) return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp?size=1024`
        return 'https://cdn.discordapp.com/embed/avatars/2.png?size=1024'
    }

    const btnColor = () => {
        if (guild.owner && guild.icon) return 'danger'
        if (guild.owner) return 'success'
        if (guild.icon) return 'dark'
        return 'secondary'
    }

    return (
        <div className="card m-1 mb-4" style={{ width: '10rem' }}>
            <img className="card-img-top"
                src={img_url()}
                alt={guild.name} />
            <div className="card-body">
                <h5 className="card-title">{guild.name}</h5>
                <Link to={`/dashboard/${guild.id}`}>
                    <button className={`btn btn-${btnColor()}`}>Dashboard</button>
                </Link>
            </div>
        </div>
    )
}

export default function GuildPreviewWrapper(props) {
    const { guilds } = props;
    return (
        <div className='row p-0 mb-5 mt-5 bg-dark text-dark rounded shadow-sm'>
            {guilds?.map(guild => (
                <GuildPreview guild={guild} />
            ))}
        </div>
    )
}